globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"G-0fK7A3hXEmYmzwmI5A5"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';

const CI_PIPELINE_ID = process.env.NEXT_PUBLIC_CI_PIPELINE_ID || 'local';
const COMMIT_HASH = process.env.NEXT_PUBLIC_CI_COMMIT_SHORT_SHA || 'local';

const setSentryUserData = (data: {
  email: string;
  role: string;
}) => {
  Sentry.setUser(data);
};

Sentry.init({
  dsn: 'https://fdc415271eaa45c7860004f15daa619f@sentry.eddev.dev/8',
  debug: false,
  release: COMMIT_HASH,

  tracesSampleRate: 1,
  enableTracing: true,
  autoSessionTracking: true,
  integrations: [
    Sentry.replayIntegration({
      beforeErrorSampling: (event) => event.level === 'fatal',
      useCompression: true,
    }),
  ],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,
});

Sentry.setTag('CI_PIPELINE_ID', CI_PIPELINE_ID);
Sentry.setTag('COMMIT_HASH', COMMIT_HASH);

window.setSentryUserData = setSentryUserData;
